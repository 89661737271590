
import { defineComponent, onBeforeMount, onMounted, ref } from 'vue'
import InputAmount from '@/components/InputAmount.vue'
import { useCarWash } from '@/composables/useCarWash'
import { useProducts } from '@/composables/useProducts'
import { IProductGroup, IProductGroupSummary } from '@/interfaces/IProduct'
import { IDemand } from '@/interfaces/IDemand'
import { useRouter, useRoute } from 'vue-router'
import { ITempComment } from '@/interfaces/IDelivery'
import localforage from 'localforage'
export default defineComponent({
    name: 'CarWashOrder',
    components: {
        InputAmount,
    },
    setup() {
        const { isLoading, activeCarWash, createCarWashDemand, fetchExpectedCarWashDemand } =
            useCarWash()
        const { productGroupList, fetchProductGroups } = useProducts()
        const productsToAdd = ref<IProductGroupSummary[]>([])
        const tempComment = ref<ITempComment>()
        const router = useRouter()
        const route = useRoute()
        const isSummary = ref(false)
        const isSubmitButtonDisabled = ref(false)
        const expectedAmount = ref()

        const toggleSummary = () => {
            if (isSummary.value) {
                isSummary.value = false
            } else {
                isSummary.value = true
            }
        }

        const setExpectedAmount = async () => {
            expectedAmount.value = await fetchExpectedCarWashDemand(+route.params.id)
            // Add expected quantity to productGroup list
            expectedAmount.value.forEach((product: IProductGroup) => {
                const expectedProduct = productGroupList.value.find(
                    productGroup => productGroup.id === product.id,
                )
                if (expectedProduct && product.expected) {
                    Object.assign(expectedProduct, { quantity: product.expected })
                    addProductAmount(expectedProduct, product.expected)
                }
            })
        }

        const addProductAmount = async (productGroup: IProductGroup, quantity: number) => {
            if (!activeCarWash.value) {
                return false
            }

            const objectToPush: IProductGroupSummary = {
                id: productGroup.id,
                symbol: productGroup.symbol,
                name: productGroup.name,
                isActive: productGroup.isActive,
                quantity,
            }

            // Check if quantity is bigger than 0 before pushing
            if (!productGroupList.value.find(elem => elem.id === objectToPush.id) && quantity > 0) {
                productGroupList.value.push(objectToPush)
            } else {
                // if object is in the array - change it's quantity
                const objectToChange = productGroupList.value.find(
                    elem => elem.id === objectToPush.id,
                )
                if (objectToChange) {
                    objectToChange.quantity = quantity
                    // if object quantity is 0 - remove object from array
                    if (objectToChange.quantity === 0) {
                        const objectToChangeIndex = productsToAdd.value.findIndex(
                            elem => elem === objectToChange,
                        )
                        productGroupList.value.splice(objectToChangeIndex, 1)
                    }
                }
                productsToAdd.value = productGroupList.value.filter(product => product.quantity > 0)
            }
        }

        onMounted(async () => {
            const storedDemand: IProductGroupSummary[] | null = await localforage.getItem(
                'currentDemand',
            )
            await fetchProductGroups()
            await setExpectedAmount()
            if (storedDemand) {
                productGroupList.value = storedDemand
                productsToAdd.value = storedDemand.filter(product => product.quantity > 0)
            }

            tempComment.value = (await localforage.getItem('tempComment')) as ITempComment
        })
        onBeforeMount(() => {
            productGroupList.value = []
        })

        const submitDemand = async () => {
            if (!activeCarWash.value) {
                return
            }
            isSubmitButtonDisabled.value = true
            const payload: IDemand = {
                carWashId: activeCarWash.value.id,
                note: tempComment.value ? tempComment.value.comment : '',
                productGroups: [],
            }

            productsToAdd.value.forEach(item => {
                const productGroupToSend = {
                    productGroupId: item.id,
                    quantity: item.quantity,
                }
                payload.productGroups.push(productGroupToSend)
            })

            const result = await createCarWashDemand(payload)
            if (result) {
                localforage.removeItem('tempComment')
                localforage.removeItem('currentDemand')
                router.push({ name: 'CarWashDetails', params: { id: activeCarWash.value.id } })
            }
        }

        const addComment = async () => {
            await localforage.setItem(
                'currentDemand',
                JSON.parse(JSON.stringify(productGroupList.value)),
            )
            router.push({
                name: 'AddCommentManager',
                params: { id: route.params.id, name: route.name as string },
            })
        }

        return {
            router,
            route,
            isSubmitButtonDisabled,
            isLoading,
            isSummary,
            toggleSummary,
            addProductAmount,
            productsToAdd,
            productGroupList,
            submitDemand,
            tempComment,
            addComment,
        }
    },
})

import { reactive, toRefs } from 'vue'
import { useApi } from '@/composables/useApi'
import { useNotifications } from '@/composables/useNotifications'
import { IProductsState } from '@/interfaces/IProduct'

const { usePost } = useApi()
const { setNotification } = useNotifications()
const state: IProductsState = reactive({
    productGroupList: [],
})

export const useProducts = () => {
    const fetchProductGroups = async () => {
        const url = `${process.env.VUE_APP_API}/v1/products-groups`
        const options = {
            page: 1,
            limit: 25,
        }
        try {
            const result = await usePost(url, options)
            state.productGroupList = result.data.data
            return state.productGroupList
        } catch (error: any) {
            setNotification(error.response.data.message)
            throw Error(error.response.data.message)
        }
    }
    return {
        ...toRefs(state),
        fetchProductGroups,
    }
}
